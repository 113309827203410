/**
 * This middleware only has an effect in Node.js.
 * @public
 */
export function agent(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _opts?: any,
): any {
  return {}
}
